// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/checkboxes_icons/star_rate_dark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/checkboxes_icons/star_rate_checked_dark.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.prof-reviews__rate-text .rate-name {\n  white-space: nowrap;\n}\n.prof-reviews__info--favourite .rate-name {\n  margin-right: 12px;\n}\n.prof-reviews__rate .rate__star.rate__star {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.prof-reviews__rate .rate__star.is-filled,\n.rate--editable .rate__star:hover,\n.rate--editable .rate__star:hover ~ .rate__star {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
