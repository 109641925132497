(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ $gettext("Interpretation types") }}</span>
      <button v-if="isAnyTypes && canEdit"
              class="edit-pencil-btn--outlined profile__title-edit-btn"
              @click="openTypesModal"></button>
      <button v-else-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openTypesModal"></button>
    </h2>
    <div v-if="isAnyTypes">
      <list :rendered-list="computedIntTypes"
            :list-with-icons="true" />
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openTypesModal">{{ $gettext("Add your preferred interpretation types") }}</button>
    </div>
  </section>
</template>)

<script>
  import List from '@/components/profile_components/show/List';

  export default {
    components: {
      list: List
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      interpretationTypes() { return this.paramsList.interpretationTypes; },
      canEdit() { return this.paramsList.canEdit; },
      isAnyTypes() {
        if (this.interpretationTypes) {
          for (let i = 0, lim = this.interpretationTypes.length; i < lim; i += 1) {
            if (this.interpretationTypes[i].checked) {
              return true;
            }
          }
        }

        return false;
      },
      computedIntTypes() {
        const res = [];
        if (this.interpretationTypes) {
          for (const type of this.interpretationTypes) {
            const obj = {};
            obj.name = this.computeTypeName(type.name);
            obj.iconName = type.name;
            obj.checked = type.checked;
            res.push(obj);
          }
        }
        return res;
      }
    },
    methods: {
      computeTypeName(name) {
        switch (name) {
          case 'video':
            return this.$gettext('Video');
          case 'phone':
            return this.$gettext('Phone');
          case 'in_person':
            return this.$gettext('In-person');
          case 'videoroom':
            return this.$gettext('Video room');
        }
      },
      makeIntTypesModalData() {
        return {
          title: this.$gettext('Interpretation types'),
          context: this,
          data: {
            data: JSON.parse(JSON.stringify(this.interpretationTypes)),
            type: 'sessionTypes'
          }
        };
      },
      openTypesModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'checkboxes-modal',
          data: this.makeIntTypesModalData()
        });
      },
      updateTypes(obj) {
        this.$store.dispatch('EditProfileStore/updateTypes', {
          id: this.$route.params.id,
          form: obj
        }).then(() => {
          this.$emit('updatesuccess');
        });
      }
    }
  };
</script>
