(<template>
  <section :class="{'in-progress': progressActive}"
           class="prof-reviews is-progress-bar">
    <div class="prof-reviews__title-cont">
      <p class="prof-reviews__title">{{ $gettext("Interpreter Reviews") }}</p>
    </div>
    <div class="prof-reviews__data-cont">
      <div class="prof-reviews__info prof-reviews__info--professional">
        <rating :title="communicationText"
                :rate="averageCommunication"
                class="prof-reviews__rate" />
        <rating :title="professionalismText"
                :rate="averageProfessionalism"
                class="prof-reviews__rate" />
        <div class="prof-reviews__rate">
          <p class="prof-reviews__rate-text"
             v-html="favouritesText"></p>
          <span class="prof-reviews__favourites"></span>
        </div>
      </div>
      <div class="prof-reviews__info prof-reviews__info--punctuality">
        <p class="prof-reviews__rate-text"
           v-html="showedUpText"></p>
        <p class="prof-reviews__rate-text"
           v-html="punctualityText"></p>
      </div>
    </div>
    <template v-if="publicFeedbacks.length">
      <div class="prof-reviews__data-cont">
        <div class="prof-reviews__delimiter"></div>
        <sk-note v-if="canEdit"
                 :text="publicFeedbacksNote"
                 class="prof-reviews__review-note" />
        <review v-for="publicFeedback in publicFeedbacks"
                :key="publicFeedback.id"
                :public-feedback="publicFeedback"
                :can-edit="canEdit"
                class="prof-reviews__review"
                @changefeedbackstatus="changeFeedbackStatus" />
        <sk-pagination :current="+currentPage"
                       :total="+totalPages"
                       class="prof-reviews__pagination"
                       @prev="toPrevPage"
                       @next="toNextPage"
                       @newpage="toNewPage" />
      </div>
    </template>
  </section>
</template>)

<script>
  import Rating from '@/components/shared_components/Rating';
  import Review from '@/components/profile_components/show/reviews/Review';

  export default {
    components: {
      review: Review,
      rating: Rating
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        progressActive: false,
        totalPages: this.params.totalPages,
        currentPage: +this.$route.query.page || 1,
        items: +this.$route.query.items || 5
      };
    },
    computed: {
      paramsList() { return this.params || {}; },
      canEdit() { return !!this.params.canEdit; },
      statsInfo() { return this.params.stats || {}; },
      reviewsCount() { return this.statsInfo.reviewsCount || 0; },
      showedUp() { return this.statsInfo.showedUp || 0; },
      showedUpCount() { return this.statsInfo.showedUpCount || 0; },
      punctuality() { return this.statsInfo.punctuality || 0; },
      punctualityCount() { return this.statsInfo.punctualityCount || 0; },
      averageRating() { return this.statsInfo.averageRating || 0; },
      averageCommunication() { return this.statsInfo.averageCommunication || 0; },
      averageProfessionalism() { return this.statsInfo.averageProfessionalism || 0; },
      favouriteCount() { return this.statsInfo.favouriteCount || 0; },
      reviewsDateFrom() { return this.statsInfo.reviewsDateFrom || ''; },
      publicFeedbacksNote() {
        return this.$gettext('Hidden feedbacks are not visible for customers on your profile page');
      },
      publicFeedbacks() { return this.paramsList.publicFeedbacks; },
      communicationText() { return this.$gettext('Communication'); },
      professionalismText() { return this.$gettext('Professionalism'); },
      reviewsText() {
        return this.genReviewTitle(this.$gettext('Reviews'), this.reviewsCount);
      },
      favouritesText() {
        return this.genReviewTitle(this.$gettext('Favourites'), this.favouriteCount);
      },
      showedUpText() {
        return this.genReviewTitle(this.$gettext('Showed up'), `${this.showedUp}/${this.showedUpCount}`);
      },
      punctualityText() {
        return this.genReviewTitle(this.$gettext('Punctuality'), `${this.punctuality}/${this.punctualityCount}`);
      },
      reviewsDateFromText() {
        if (this.reviewsDateFrom) {
          const dateMoment = this.$moment(this.reviewsDateFrom);
          return `${this.$gettext('Reviews from:')} ${dateMoment.date()}&nbsp;${this.$helperData.getMonthNames(this)[dateMoment.month()].slice(0, 3)}&nbsp;${dateMoment.year()}`;
        }
      },
      isPublicRoute() { return this.$route.name === 'PublicProfile'; }
    },
    watch: {
      currentPage() {
        this.getInterpreterFeedback();
      },
    },
    methods: {
      genReviewTitle(name, value) {
        const template = this.$gettext('<span class="rate-name">%{name}</span> <b class="rate-value">%{value}</b>');
        return this.$gettextInterpolate(template, {name, value});
      },
      changeFeedbackStatus(feedbackId, status) {
        this.progressActive = true;
        this.$store.dispatch('EditProfileStore/changePublicFeedbackStatus', {
          feedbackId,
          status
        })
          .then(() => {
            this.getInterpreterFeedback();
          })
          .catch((error) => {
            this.progressActive = false;
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          });
      },
      getInterpreterFeedback() {
        this.progressActive = true;
        let payload = {
          params: {
            page: this.currentPage,
            items: this.items
          }
        };
        if (this.isPublicRoute) {
          payload = {
            ...payload,
            slug: this.$route.params.slug,
            country: this.$route.params.int_country
          };
        } else {
          payload = {
            ...payload,
            id: this.$route.params.id,
          };
        }
        this.$store.dispatch('EditProfileStore/getInterpreterFeedback', payload).then((res) => {
          this.totalPages = res.pages;
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      // *** pagination methods ***//
      toPrevPage() {
        this.currentPage -= 1;
      },
      toNewPage(page) {
        this.currentPage = +page;
      },
      toNextPage() {
        this.currentPage += 1;
      },
    }
  };
</script>

<style>
  .prof-reviews__rate-text .rate-name {
    white-space: nowrap;
  }

  .prof-reviews__info--favourite .rate-name {
    margin-right: 12px;
  }

  .prof-reviews__rate .rate__star.rate__star {
    background-image: url(~@assets/imgs/checkboxes_icons/star_rate_dark.svg);
  }

  .prof-reviews__rate .rate__star.is-filled,
  .rate--editable .rate__star:hover,
  .rate--editable .rate__star:hover ~ .rate__star {
    background-image: url(~@assets/imgs/checkboxes_icons/star_rate_checked_dark.svg);
  }
</style>

<style scoped>
  .prof-reviews__title-cont {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .prof-reviews__title {
    font-weight: bold;
    font-size: 18px;
  }

  .prof-reviews__data-cont {
    display: flex;
    flex-wrap: wrap;
  }

  .prof-reviews__delimiter {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #e5e5e5;
  }

  .prof-reviews__review-note {
    margin-bottom: 15px;
  }

  .prof-reviews__review {
    margin-bottom: 20px;
  }

  .prof-reviews__review:last-child {
    margin-bottom: 0;
  }

  .prof-reviews__pagination {
    margin: auto;
  }

  .prof-reviews__info {
    margin-right: 20px;
  }

  .prof-reviews__rate {
    display: flex;
    height: 20px;
    margin-bottom: 10px;
  }

  .prof-reviews__rate-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 12px;
  }

  .prof-reviews__info--professional .prof-reviews__rate {
    justify-content: space-between;
  }

  .prof-reviews__rate:first-child,
  .prof-reviews__info--punctuality .prof-reviews__rate-text:first-child {
    margin-bottom: 10px;
  }

  .prof-reviews__favourites {
    display: inline-block;
    flex-shrink: 0;
    width: 100px;
    height: 19px;
    background-image: url(~@assets/imgs/checkboxes_icons/heart-checkbox-selected-logo_red.svg);
    background-clip: content-box;
    background-size: 18px auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 767px) {
    .prof-reviews__info {
      margin-bottom: 20px;
    }

    .prof-reviews__info:last-child {
      margin-bottom: 0;
    }
  }
</style>
