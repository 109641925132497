(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ $gettext("Experiences") }}</span>
      <button v-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openEditModal"></button>

    </h2>
    <div v-if="isAnyExperiences">
      <experience v-for="experience in experiences"
                  :key="experience.id"
                  :experience="experience"
                  :can-edit="canEdit"
                  @editExperience="editOneExperience" />
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openEditModal">{{ $gettext("Add your Experiences") }}</button>
    </div>
  </section>
</template>)

<script>
  import Experience from '@/components/profile_components/show/OneExperience';

  export default {
    components: {
      experience: Experience
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      experiences() { return this.paramsList.experiences; },
      canEdit() { return this.paramsList.canEdit; },
      isAnyExperiences() {
        return !!(this.experiences && this.experiences.length);
      }
    },
    methods: {
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'experience-modal',
          data: this.makeOneExperiencesModalData(true)
        });
      },
      editOneExperience(id) {
        this.$store.commit('ModalStore/setModal', {
          component: 'experience-modal',
          data: this.makeOneExperiencesModalData(true, id)
        });
      },
      makeExperienceObj(experienceId) {
        for (const experience of this.experiences) {
          if (experience.id == experienceId) {
            return experience;
          }
        }
      },
      makeOneExperiencesModalData(experiences, experienceId) {
        return {
          title: this.$gettext('Experience'),
          context: this,
          data: experiences && !experienceId ? {} : this.makeExperienceObj(experienceId)
        };
      },
      addExperience(payload) {
        this.$store.dispatch('EditProfileStore/addExperience', {
          uid: this.$route.params.id,
          payload: payload
        }).then(() => {
          this.$emit('updatesuccess');
        });
      },
      editExperience(payload, id) {
        this.$store.dispatch('EditProfileStore/editExperience', {
          uid: this.$route.params.id,
          payload: payload,
          experienceId: id
        }).then(() => {
          this.$emit('updatesuccess');
        });
      }
    }
  };
</script>
