(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title">
      <span>{{ $gettext("About") }}</span>
      <div class="profile__section-btn-wrapper">
        <button v-if="aboutText && canEdit"
                class="edit-pencil-btn--outlined profile__title-edit-btn"
                @click="openAboutModal">
        </button>
        <button v-else-if="canEdit"
                class="add-plus-btn profile__title-edit-btn"
                @click="openAboutModal"></button>
      </div>
    </h2>
    <div v-if="aboutText">
      <p class="prof-about-section__text"
         itemprop="description">{{ aboutText }}</p>
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openAboutModal">{{ $gettext("I became an interpreter because...") }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      aboutText() { return this.paramsList.aboutText; },
      canEdit() { return this.paramsList.canEdit; }
    },
    methods: {
      makePersonAboutModalData() {
        return {
          title: this.$gettext('About'),
          context: this,
          data: {about: this.aboutText}
        };
      },
      openAboutModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'person-about',
          data: this.makePersonAboutModalData()
        });
      },
      updateAboutInfo(obj) {
        this.$store.dispatch('EditProfileStore/updateUserProfile', {
          id: this.$route.params.id,
          form: obj
        }).then(() => {
          this.$emit('updatesuccess');
        }).catch((error) => {
          this.$emit('updatefailed', error);
        });
      }
    }
  };
</script>

<style scoped>
  .prof-about-section__text {
    display: block;
    width: 100%;
    margin: 0;
    font-size: 14px;
  }
</style>
