(<template>
  <section class="public-profile__main-wrapper is-progress-bar"
           :class="{'in-progress': !receivedPublicInfo}">
    <public-url :base="baseLink"
                :slug="slug"
                :country="slugCountry"
                @slugchanged="changeSlug" />
    <public-settings :level="level"
                     :settings="settings"
                     @savesettings="changeSettings" />
  </section>
</template>)

<script>
  import PublicSettings from '@/components/profile_components/public/PublicSettings';
  import PublicUrl from '@/components/profile_components/public/PublicUrl';

  const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

  export default {
    components: {
      'public-url': PublicUrl,
      'public-settings': PublicSettings
    },
    data() {
      return {
        receivedPublicInfo: true
      };
    },
    computed: {
      level() {
        return this.$store.state.EditProfileStore.settings ? this.$store.state.EditProfileStore.settings.setting ? this.$store.state.EditProfileStore.settings.setting.privacy : '' : '';
      },
      settings() {
        return this.$store.state.EditProfileStore.settings ? this.$store.state.EditProfileStore.settings.setting ? this.$store.state.EditProfileStore.settings.setting.hidden_info : [] : [];
      },
      slug() {
        return this.$store.state.EditProfileStore.settings ? this.$store.state.EditProfileStore.settings.setting ? this.$store.state.EditProfileStore.settings.setting.slug : '' : '';
      },
      slugCountry() {
        return this.$store.state.EditProfileStore.settings ? this.$store.state.EditProfileStore.settings.setting ? this.$store.state.EditProfileStore.settings.setting.slug_country : '' : '';
      },
      baseLink() {
        if (isBrowser()) {
          return window.location.host;
        }
      }
    },
    methods: {
      changeSlug(newSlug) {
        // let self = this;
        // let ajaxData = {
        //   slug: newSlug
        // };
        // this.startPublicProgress();
        // $.ajax({
        //   url: `/people/${this.userID}/slug`,
        //   type: "PATCH",
        //   data: JSON.stringify(ajaxData),
        //   dataType: 'json',
        //   contentType: 'application/json',
        //   success: function(response) {
        //     self.publicData.slug = newSlug;
        //     self.stopPublicProgress();
        //   },
        //   error: function(response) {
        //     var errors = $.parseJSON(response.responseText);
        //     showErrorsModule.showMessage(errors, "main-error");
        //     self.stopPublicProgress();
        //   }
        // });
        const form = new FormData();
        form.append('slug', newSlug);
        this.receivedPublicInfo = false;
        this.$store.dispatch('EditProfileStore/changeSlug', form).then(() => {
          this.receivedPublicInfo = true;
        });
      },
      changeSettings(obj) {
        const form = new FormData();

        form.append('setting[privacy_level]', obj.level);
        for (const str of obj.array) {
          form.append('setting[hidden_infos_attributes][]', str);
        }
        this.receivedPublicInfo = false;
        this.$store.dispatch('EditProfileStore/updateSettings', form).then(() => {
          this.receivedPublicInfo = true;
        });
      }
    }
  };
</script>

<style>
  .public-profile__main-wrapper {
    display: block;
    width: 100%;
  }
</style>
