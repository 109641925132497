(<template>
  <section :class="{'review--hidden': isHiddenFeedback}"
           class="review">
    <div class="review__header-wrapper">
      <h4 class="review__title"
          v-html="title"></h4>
      <button v-if="canEdit"
              class="sk-link sk-link--gray-bg review__link"
              @click="changeFeedbackStatus">{{ showingButtonText }}</button>
    </div>
    <div>
      <pre class="review__comment">{{ comment }}</pre>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      publicFeedback: {
        type: Object,
        default() {
          return {
            id: '',
            comment: '',
            author: ''
          };
        }
      },
      canEdit: Boolean
    },
    computed: {
      title() {
        const template = this.$gettext('From:');

        return `${template} ${this.publicFeedback.author}`;
      },
      comment() {
        return this.publicFeedback.comment || '';
      },
      isHiddenFeedback() {
        return this.publicFeedback.status === 'hidden';
      },
      showingButtonText() {
        return this.isHiddenFeedback ? this.$gettext('Show feedback') : this.$gettext('Hide feedback');
      }
    },
    methods: {
      changeFeedbackStatus() {
        return this.$emit('changefeedbackstatus', this.publicFeedback.id, this.isHiddenFeedback ? 'published' : 'hidden');
      }
    }
  };
</script>

<style scoped>
  .review {
    width: 100%;
  }

  .review--hidden {
    opacity: 0.5;
  }

  .review__header-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .review__title {
    margin-right: 10px;
    margin-bottom: 5px;
    line-height: 1.29;
    word-break: break-all;
  }

  .review__link {
    color: #333;
    font-weight: normal;
    white-space: nowrap;
  }

  .review__comment {
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
</style>
