(<template>
  <div class="profile-data">
    <profile-section v-for="(component, index) in filteredComponentsList"
                     :key="(component.name+index)"
                     class="profile-data__section">
      <component :is="component.name"
                 :params="component.params" />
    </profile-section>
  </div>
</template>)

<script>
  import About from '@/components/profile_components/show/About';
  import Badges from '@/components/profile_components/show/Badges';
  import Areas from '@/components/profile_components/show/Areas';
  import Methods from '@/components/profile_components/show/Methods';
  import Types from '@/components/profile_components/show/Types';
  import Schools from '@/components/profile_components/show/Schools';
  import Courses from '@/components/profile_components/show/Courses';
  import Referal from '@/components/profile_components/show/Referal';
  import Skills from '@/components/profile_components/show/Skills';
  import Reviews from '@/components/profile_components/show/reviews/Reviews';
  import PublicProfile from '@/components/profile_components/public/PublicProfileSettings';
  import Tools from '@/components/profile_components/show/Tools';
  import Experiences from '@/components/profile_components/show/Experiences';
  import DeleteAccountBtn from '@/components/profile_components/show/DeleteAccountBtn';
  import ApprovedInterpretingSkills from '@/components/profile_components/show/ApprovedInterpretingSkills';
  import ProfileSection from './ProfileSection';
  // import AuthProviders from '@/components/profile_components/show/AuthProviders';

  export default {
    components: {
      ProfileSection,
      schools: Schools,
      courses: Courses,
      badges: Badges,
      referral: Referal,
      skills: Skills,
      reviews: Reviews,
      'about-info': About,
      'expertise-areas': Areas,
      methods: Methods,
      'int-types': Types,
      // 'auth-providers': AuthProviders,
      'public-profile': PublicProfile,
      tools: Tools,
      experiences: Experiences,
      'delete-account-btn': DeleteAccountBtn,
      'approved-interpreting-skills': ApprovedInterpretingSkills
    },
    props: {
      componentsList: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      filteredComponentsList() {
        return this.componentsList.filter(({condition}) => {
          return condition !== undefined ? condition : true;
        });
      }
    }
  };
</script>

<style scoped>
  .profile-data__section {
    margin-bottom: 20px;
  }
</style>
