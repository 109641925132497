(<template>
  <section class="public-url__main-wrapper">
    <h2 class="profile__section-title">{{ $gettext("Edit profile URL") }}</h2>
    <section class="public-url__text-wrapper">
      <p class="public-url__simple-text">{{ $gettext("Enhance your personal brand by creating a custom URL for your profile.") }}</p>
      <div v-if="isFormOpened">
        <p class="public-url__bottom-text">{{ linkWithoutSlug }}</p>
        <sk-input :description="text1"
                  :preselected-value="newSlug"
                  :placeholder="text1"
                  @fieldvaluechanged="catchNewSlug" />
        <p v-if="freeName !== 1"
           class="public-url__availability-text"
           :class="{'public-url__availability-text--red': !freeName}">{{ availabilityText }}</p>
        <submit-btns :submit-handler="submitHandler"
                     :cancel-handler="closePublicForm"
                     :class="['is-progress-bar', {'in-progress': inSearchProgress}]" />
      </div>
      <div v-else>
        <a :href="fullHref"
           target="_blank"
           class="sk-link sk-link--gray-bg public-url__bottom-text">{{ fullLink }}</a>
        <button class="edit-pencil-btn--outlined public-url__edit-btn"
                @click="openPublicURLform"></button>
      </div>
    </section>
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      base: {
        type: String,
        default: ''
      },
      slug: {
        type: String,
        default: ''
      },
      country: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isFormOpened: false,
        newSlug: '',
        freeName: 1,
        errorTimer: '',
        ajaxTimer: '',
        inSearchProgress: false
      };
    },
    computed: {
      fullLink() {
        return `${this.base}/${this.country}/${this.slug}`;
      },
      fullHref() {
        return `${this.fullLink}`;
      },
      linkWithoutSlug() {
        return `${this.base}/${this.country}`;
      },
      availabilityText() {
        return this.freeName ? this.$gettext('This URL is available!') : this.$gettext('This URL has already been taken by another user or is not valid.');
      },
      text1() { return this.$gettext('Public URL'); }
    },
    watch: {
      slug() {
        this.isFormOpened = false;
      },
      newSlug() {
        if (this.newSlug != this.slug && this.newSlug.trim()) {
          this.inSearchProgress = true;
          this.checkAvailability();
        } else {
          clearInterval(this.ajaxTimer);
          this.inSearchProgress = false;
          this.freeName = 1;
        }
      }
    },
    methods: {
      catchNewSlug(value) {
        this.newSlug = value;
      },
      openPublicURLform() {
        this.newSlug = this.slug;
        this.isFormOpened = true;
      },
      submitHandler() {
        clearInterval(this.errorTimer);
        if (this.newSlug !== this.slug && this.newSlug.trim()) {
          if (this.freeName) {
            this.$emit('slugchanged', this.newSlug);
          }
        } else {
          this.closePublicForm();
        }
      },
      closePublicForm() {
        this.newSlug = '';
        clearInterval(this.ajaxTimer);
        this.freeName = true;
        clearInterval(this.errorTimer);
        this.inSearchProgress = false;
        this.isFormOpened = false;
      },
      checkAvailability() {
        const self = this;
        clearInterval(this.ajaxTimer);
        this.ajaxTimer = setTimeout(() => {
          self.inSearchProgress = true;
          self.$store.dispatch('EditProfileStore/checkAvailableSlug', this.newSlug.trim()).then((response) => {
            self.freeName = response.available;
            self.inSearchProgress = false;
          });
        }, 800);
      }
    }
  };
</script>

<style>
  .public-url__main-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }

  .public-url__text-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px 10px;
    padding-right: 45px;
    border-radius: 3px;
    background-color: #f4f6f8;
  }

  .public-url__simple-text {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .public-url__bottom-text {
    margin-bottom: 10px;
    word-break: break-word;
  }

  .public-url__edit-btn {
    position: absolute;
    right: 10px;
    bottom: 20px;
  }

  .public-url__availability-text {
    display: block;
    width: 100%;
    margin: 0;
    margin-top: 5px;
    color: #ff5b24;
    color: var(--color-secondary);
    font-size: 10px;
  }

  .public-url__availability-text--red {
    color: #646d8c;
    color: var(--color-primary);
  }
</style>
