(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ $gettext("Education") }}</span>
      <button v-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openEditModal"></button>

    </h2>
    <div v-if="isAnySchools">
      <school v-for="school in schools"
              :key="school.id"
              :school="school"
              :can-edit="canEdit"
              @editschool="editOneSchool" />
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openEditModal">{{ $gettext("Add your education") }}</button>
    </div>
  </section>
</template>)

<script>
  import School from '@/components/profile_components/show/OneSchool';

  export default {
    components: {
      school: School
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      schools() { return this.paramsList.schools; },
      canEdit() { return this.paramsList.canEdit; },
      isAnySchools() {
        return !!(this.schools && this.schools.length);
      }
    },
    methods: {
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'schools-modal',
          data: this.makeOneSchoolModalData(true)
        });
      },
      editOneSchool(id) {
        this.$store.commit('ModalStore/setModal', {
          component: 'schools-modal',
          data: this.makeOneSchoolModalData(true, id)
        });
      },
      makeSchoolObj(schoolId) {
        for (const school of this.schools) {
          if (school.id == schoolId) {
            return school;
          }
        }
      },
      makeOneSchoolModalData(newSchool, schoolId) {
        return {
          title: this.$gettext('Education'),
          context: this,
          data: newSchool && !schoolId ? {} : this.makeSchoolObj(schoolId)
        };
      },
      addSchool(obj) {
        this.$store.dispatch('EditProfileStore/addSchool', {
          id: this.$route.params.id,
          form: obj
        }).then(() => {
          this.$emit('updatesuccess');
        });
      },
      editSchool(obj, id) {
        this.$store.dispatch('EditProfileStore/editSchool', {
          id: this.$route.params.id,
          form: obj,
          schoolId: id
        }).then(() => {
          this.$emit('updatesuccess');
        });
      }
    }
  };
</script>

<style scoped>
  .prof-education-modal__content-wrapper {
    display: block;
    width: 100%;
  }

  .prof-education-modal__add-school-btn {
    display: inline-block;
    width: auto;
    padding: 0 10px;
  }
</style>
