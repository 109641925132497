<template>
  <div :class="{'in-progress': progressActive}"
       class="profile-section is-progress-bar">
    <div v-if="title"
         class="profile-section__title-wrapper">
      <h3 class="profile-section__title"
          v-html="title"></h3>
    </div>
    <div class="profile-section__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      progressActive: Boolean
    }
  };
</script>

<style scoped>
  .profile-section {
    padding: 15px 25px 25px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  }

  .profile-section__title-wrapper {
    display: flex;
    margin-bottom: 20px;
  }

  .profile-section__title {
    display: flex;
    width: 100%;
    font-size: 18px;
  }

  @media (max-width: 767px) {
    .profile-section {
      padding: 20px 15px 30px;
    }
  }
</style>
