(<template>
  <div :class="{'in-progress': progressActive}"
       class="prof-favor-block is-progress-bar">
    <div class="prof-favor-block__wrapper">
      <button :class="{'favourite-btn--favorite--chosen': currentState === 'favourite'}"
              class="favourite-btn favourite-btn--favorite"
              :disabled="disable"
              @click="likeHandler">{{ $gettext('Favourite') }}</button>
      <button :class="{'favourite-btn--block--chosen': currentState === 'blocked'}"
              class="favourite-btn favourite-btn--block"
              :disabled="disable"
              @click="openInterBlockModal">{{ $gettext('Block') }}</button>
      <span class="prof-favor-block__tooltip-imitation is-hidden"></span>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    props: {
      firstName: {
        type: String,
        default: ''
      },
      uid: {
        type: String,
        default: ''
      },
      disable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        progressActive: false
      };
    },
    computed: {
      ...mapState('EditProfileStore', {
        person: (state) => {
          return state.profileData && state.profileData.person ? state.profileData.person : '';
        },
        currentState: (state) => {
          return state.profileData && state.profileData.relation ? state.profileData.relation : '';
        },
      }),
      interpreterName() {
        return this.person ? `${this.person.firstName || ''} ${this.person.lastName || ''}` : '';
      },
    },
    methods: {
      removeRelation() {
        this.progressActive = true;
        this.$store.dispatch('EditProfileStore/removeRelation', this.$route.params.id).then(() => {
          this.progressActive = false;
        }).catch((e) => {
          // if already blocked removed refresh page
          if (e.errors.interpreter[0] == 'You have already unblocked this interpreter') this.$router.go();
        });
      },
      setFavor() {
        const form = new FormData();
        form.append('relation[interpreter]', this.$route.params.id);
        form.append('relation[attitude_type]', 'favourite');
        this.progressActive = true;
        this.$store.dispatch('EditProfileStore/sendUpdatedRelation', {form, relation: 'favourite'}).then(() => {
          this.progressActive = false;
        });
      },
      openInterBlockModal() {
        const temp = this.$gettext('%{oppositeRelation} interpreter %{interpreterName} from future assignments for your department(s)');
        const oppositeRelation = this.currentState === 'blocked' ? this.$gettext('Unblock') : this.$gettext('Block');
        this.$store.commit('ModalStore/setModal', {
          component: 'block-interpreter-modal',
          classes: ['sk-modal--new'],
          width: 600,
          data: {
            title: this.$gettextInterpolate(temp, {interpreterName: this.interpreterName, oppositeRelation}),
            interpreterName: this.interpreterName
          }
        });
      },
      likeHandler() {
        if (this.currentState == 'favourite') {
          this.removeRelation();
        } else {
          this.setFavor();
        }
      }
    }
  };
</script>

<style scoped>
  .prof-favor-block__wrapper {
    position: relative;
    display: inline-block;
    margin-top: 5px;
  }

  .prof-favor-block__wrapper button:first-of-type {
    margin-right: 5px;
  }

  .prof-favor-block__tooltip-imitation {
    position: absolute;
    top: 7px;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid red;
  }

  @media (max-width: 768px) {
    .prof-favor-block {
      position: static;
    }

    .prof-favor-block__wrapper {
      display: flex;
      justify-content: space-between;
    }

    .favourite-btn {
      width: 140px;
    }
  }
</style>
