(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ $gettext("Additional interpretation courses") }}</span>
      <button v-if="isAnyCourses && canEdit"
              class="edit-pencil-btn--outlined profile__title-edit-btn"
              @click="openEditModal"></button>
      <button v-else-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openEditModal"></button>
    </h2>
    <div v-if="isAnyCourses">
      <li v-for="(course, index) in courses"
          :key="index"
          class="course-item">{{ course }}</li>
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openEditModal">{{ $gettext("Add interpretation courses") }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      courses() { return this.paramsList.courses; },
      canEdit() { return this.paramsList.canEdit; },
      isAnyCourses() {
        return !!(this.courses && this.courses.length);
      }
    },
    methods: {
      makeCoursesModalData() {
        return {
          title: this.$gettext('Courses'),
          context: this,
          data: {
            courses: this.courses || []
          }
        };
      },
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'courses-modal',
          data: this.makeCoursesModalData()
        });
      },
      updateCourses(obj) {
        this.$store.dispatch('EditProfileStore/updateUserProfile', {
          id: this.$route.params.id,
          form: obj
        }).then(() => {
          this.$emit('updatesuccess');
        });
      }
    }
  };
</script>

<style scoped>
.course-item {
  padding-bottom: 5px;
}
</style>
