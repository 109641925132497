(<template>
  <article class="prof-one-school__wrapper">
    <p class="prof-one-school__title">
      {{ title }}
      <button v-if="canEdit"
              class="edit-pencil-btn--outlined prof-one-school__edit-btn"
              @click="openEditModal"></button>
    </p>
    <p class="prof-one-school__degree">{{ school.degree }}</p>
    <p class="prof-one-school__period">{{ schoolYears }}</p>
  </article>
</template>)

<script>
  export default {
    props: {
      school: {
        type: Object,
        default() {
          return {};
        }
      },
      canEdit: Boolean
    },
    computed: {
      schoolYears() {
        return this.school.year_from && this.school.year_to ? [this.school.year_from, this.school.year_to].join('-') : '';
      },
      schoolID() {
        return this.school.id;
      },
      title() { return this.school.location ? `${this.school.school}, ${this.school.location}` : `${this.school.school}`; }
    },
    methods: {
      openEditModal() {
        this.$emit('editschool', this.schoolID);
      }
    }
  };
</script>

<style scoped>
  .prof-one-school__wrapper {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .prof-one-school__title {
    position: relative;
    display: flex;
    padding-right: 35px;
    font-size: 14px;
  }

  .prof-one-school__degree {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .prof-one-school__period {
    color: rgba(51, 51, 51, 0.5);
    font-size: 12px;
  }

  .prof-one-school__edit-btn {
    position: absolute;
    top: -5px;
    right: 0;
  }
</style>
