(<template>
  <section ref="referalSection"
           class="prof-referal-section__wrapper is-progress-bar">
    <div v-if="!!referral">
      <p class="prof-referal-section__big-text">{{ $gettext("Referral code") }}</p>
      <p class="prof-referal-section__text">{{ referral }}</p>
    </div>
    <div v-else>
      <p class="prof-referal-section__big-text">{{ $gettext("Did a friend refer you to us?") }}</p>
      <p class="prof-referal-section__text">{{ $gettext("Tell us their referral code, so we can thank them for their help!") }}</p>
      <form class="prof-referal-section__form"
            @submit.prevent="saveReferralID">
        <input v-model="newReferral"
               class="skiwo-input prof-referal-section__input"
               type="text"
               :placeholder="text1">
        <button class="sk-btn sk-btn--default prof-referal-section__submit-btn">{{ $gettext("Save") }}</button>
      </form>
    </div>
  </section>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        newReferral: ''
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid']),
      paramsList() { return this.params || {}; },
      referral() { return this.paramsList.referral; },
      text1() { return this.$gettext('Referral code'); }
    },
    methods: {
      saveReferralID() {
        // let self = this;
        // let ajaxData = {
        //   id: this.userUid,
        //   person: {
        //     referrer_id: this.newReferral
        //   }
        // };
        // this.$refs.referalSection.classList.add('in-progress');

        // $.ajax({
        //   url: profilePageBUS.updateInfoPath,
        //   type: 'PATCH',
        //   data: JSON.stringify(ajaxData),
        //   dataType: 'json',
        //   contentType: 'application/json',
        //   success: function(response) {
        //     self.$refs.referalSection.classList.remove('in-progress');
        //     profilePageBUS.updateReferral(self.newReferral, response);
        //   },
        //   error: function(response) {
        //     var errors = $.parseJSON(response.responseText);
        //     self.$refs.referalSection.classList.remove('in-progress');
        //     showErrorsModule.showMessage(errors, 'main-error');
        //   }
        // });
      }
    }
  };
</script>

<style scoped>
  .prof-referal-section__wrapper {
    display: block;
    width: 100%;
    max-width: 530px;
    padding: 20px 25px;
    border-radius: 3px;
    background-color: #f0e9f6;
  }

  .prof-referal-section__big-text {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 25px;
  }

  .prof-referal-section__text {
    display: block;
    width: 100%;
    margin-bottom: 0;
    font-size: 14px;
  }

  .prof-referal-section__form {
    display: flex;
    width: 100%;
    max-width: 260px;
    margin: 0;
    margin-top: 20px;
    padding: 0;
  }

  .prof-referal-section__input,
  .prof-referal-section__input:focus {
    display: block;
    flex-grow: 1;
    width: 100%;
    height: 44px;
    margin-right: 10px;
    padding: 0 10px;
    border: 0;
    border-radius: 3px !important;
    background-color: #fff;
    font-weight: normal;
    font-size: 14px;
    line-height: 42px;
  }

  .prof-referal-section__submit-btn {
    display: inline-block;
    flex-shrink: 0;
    width: auto;
    padding: 0 20px;
  }
</style>
