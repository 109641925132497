(<template>
  <div>
    <div v-if="filteredList.length">
      <ul class="prof-areas-methods__list"
          :style="listStyles">
        <li v-for="item in filteredList"
            :key="item.name"
            class="prof-areas-methods__list-item"
            :class="[computedClassName(item.iconName), `list-style--${listStyle}`]">
          <p class="prof-areas-methods__list--title">{{ item.name }}</p>
          <p class="prof-areas-methods__list-subtitle">{{ item.description }}</p>
        </li>
      </ul>
    </div>
    <div v-else>
      <button class="profile__add-info-btn">{{ $gettext("Add information") }}</button>
    </div>
  </div>
</template>)

<script>
  export default {
    props: {
      renderedList: {
        type: Array,
        default: () => []
      },
      listWithIcons: Boolean,
      listColumnsNumber: {
        type: Number,
        default: 1
      },
      listStyle: {
        type: String,
        default: ''
      }
    },
    computed: {
      filteredList() {
        return this.renderedList.filter(({checked}) => checked) || [];
      },
      classNameCondition() {
        return this.listWithIcons;
      },
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth;
      },
      listStyles() {
        if (this.clientWidth >= 767) {
          return {
            columns: this.listColumnsNumber,
          };
        } else {
          return {
            columns: 1,
          };
        }
      }
    },
    methods: {
      computedClassName(iconName) {
        if (this.classNameCondition) {
          return `prof-areas-methods__list-item--${iconName}`;
        }
        return '';
      },
    },
  };
</script>

<style scoped>
  .prof-areas-methods__list {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .prof-areas-methods__list-item {
    display: block;
    width: 100%;
    height: 25px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    line-height: 25px;
  }

  .list-style--default {
    position: relative;
    display: list-item;
    margin-left: 20px;
    list-style-type: disc;
  }

  .prof-areas-methods__list-item:last-child {
    margin-bottom: 0;
  }

  .prof-areas-methods__list--title {
    font-size: 14px;
  }

  .prof-areas-methods__list-subtitle {
    color: #89898a;
    font-size: 13px;
  }

  .prof-areas-methods__list-item--video {
    padding-left: 40px;
    background-image: url(~@assets/imgs/assignment_types/job_type_video_icon_profile.svg);
    background-position: 0 30%;
    background-size: 27px 15px;
  }

  .prof-areas-methods__list-item--phone {
    padding-left: 40px;
    background-image: url(~@assets/imgs/assignment_types/job_type_phone_icon_profile.svg);
    background-position: 0 30%;
    background-size: 26px 25px;
  }

  .prof-areas-methods__list-item--in_person {
    padding-left: 40px;
    background-image: url(~@assets/imgs/assignment_types/job_type_location_icon.svg);
    background-position: 0.15% 30%;
    background-size: 27px 25px;
  }

  .prof-areas-methods__list-item--videoroom {
    padding-top: 5px;
    padding-left: 40px;
    background-image: url(~@assets/imgs/assignment_types/job_type_hub_icon.svg);
    background-position: 0.25% 30%;
    background-size: 30px 25px;
  }
</style>
