(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ $gettext("Areas of expertise") }}</span>
      <button v-if="isAnyAreas && canEdit"
              class="edit-pencil-btn--outlined profile__title-edit-btn"
              @click="openAreasModal"></button>
      <button v-else-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openAreasModal"></button>
    </h2>
    <div v-if="isAnyAreas">
      <list :rendered-list="areasOfExpertise"
            :list-columns-number="1" />
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openAreasModal">{{ $gettext("Add your areas of expertise") }}</button>
    </div>
  </section>
</template>)

<script>
  import List from '@/components/profile_components/show/List';

  export default {
    components: {
      list: List
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      areasOfExpertise() { return this.paramsList.areasOfExpertise; },
      canEdit() { return this.paramsList.canEdit; },
      isAnyAreas() {
        if (this.areasOfExpertise) {
          for (let i = 0, lim = this.areasOfExpertise.length; i < lim; i += 1) {
            if (this.areasOfExpertise[i].checked) {
              return true;
            }
          }
        }

        return false;
      }
    },
    methods: {
      makeExpAreasModalData() {
        return {
          title: this.$gettext('Areas of expertise'),
          context: this,
          data: {
            data: JSON.parse(JSON.stringify(this.areasOfExpertise)),
            type: 'expertiseAreas'
          }
        };
      },
      openAreasModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'checkboxes-modal',
          data: this.makeExpAreasModalData()
        });
      },
      updateAreas(obj) {
        this.$store.dispatch('EditProfileStore/updateAreas', {
          id: this.$route.params.id,
          form: obj
        }).then(() => {
          this.$emit('updatesuccess');
        });
      }
    }
  };
</script>
