(<template>
  <article :class="{'in-progress': progressActive,
                    'skill-tile--overlapped': showOverlappingParagraphs}"
           class="skill-tile is-progress-bar"
           itemprop="itemListElement"
           itemscope
           itemtype="http://schema.org/CreativeWork">
    <h4 :class="[`skill-tile__skill-type--${skillType}`, {'skill-tile__skill-type--declined': declinedSkill}]"
        class="skill-tile__skill-type">{{ skillTitle }}</h4>
    <button v-if="canEdit && !userIsPayrollInterpreter"
            class="edit-pencil-btn--outlined skill-tile__edit-btn"
            @click="openSkillModal"></button>
    <div class="skill-tile__wrapper">
      <p hidden
         itemprop="description">Interpretation Skill</p>
      <section>
        <div class="skill-tile__langs-cont">
          <div class="skill-tile__lang skill-tile__lang--from"
               itemprop="name">{{ langFromName }}</div>
          <p class="skill-tile__lang--dash">-</p>
          <div class="skill-tile__lang skill-tile__lang--to"
               itemprop="name">{{ langToName }} <br />
            <span v-if="oneSkill.dialectTo"
                  class="skill-tile__dialect-to">({{ oneSkill.dialectTo }})</span>
          </div>
        </div>
      </section>
      <div class="skill-tile__qualification-cont">
        <p v-if="qualificationName"
           class="skill-tile__qualification"
           v-html="qualificationName"></p>
        <button v-if="isAnyCertifs && showCertificates"
                class="skill-tile__certifs-btn"
                @click="certificatesViewToggle = !certificatesViewToggle"></button>
      </div>
      <section v-if="certificatesViewToggle">
        <button v-for="(certif, index) in oneSkill.certificates"
                :key="index"
                class="skill-tile__one-certif-link"
                target="_blank"
                @click="openLink(certif)">{{ certifDescription(certif) }}</button>
      </section>
      <admin-only-note v-if="$isGodmode() && !isTranslation"
                       class="skill-tile__action-wrapper">
        <p class="skill-tile__status"
           v-html="skillStatus"></p>
        <div class="skill-tile__btns-wrapper">
          <template v-if="!approvedSkill">
            <button class="sk-btn sk-btn--orange skill-tile__btn"
                    @click="updateCurrentSkill('approved', true)">{{ $gettext('Approve silently') }}</button>
            <button class="sk-btn sk-btn--default skill-tile__btn"
                    @click="updateCurrentSkill('approved', false)">{{ $gettext('Approve') }}</button>
          </template>
          <template v-if="!declinedSkill">
            <button class="sk-btn sk-btn--orange skill-tile__btn"
                    @click="updateCurrentSkill('declined', true)">{{ $gettext('Decline silently') }}</button>
            <button class="sk-btn sk-btn--default skill-tile__btn"
                    @click="updateCurrentSkill('declined', false)">{{ $gettext('Decline') }}</button>
          </template>
        </div>
      </admin-only-note>
      <div v-else-if="showOverlappingParagraphs"
           :class="{'skill-tile__unapproved-wrapper--declined': declinedSkill}"
           class="skill-tile__unapproved-wrapper">
        <div class="skill-tile__unapproved-text-cont">
          <p v-for="(paragraph, index) in overlappingBlockParagraphs"
             :key="index"
             :class="paragraph.classes"
             class="skill-tile__unapproved-text"
             v-html="paragraph.text"></p>
        </div>
      </div>
    </div>
  </article>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import constants from '@/modules/constants';
  import AdminOnlyNote from '@/components/shared_components/AdminOnlyNote';

  export default {
    components: {
      'admin-only-note': AdminOnlyNote
    },
    props: {
      oneSkill: {
        type: Object,
        default() {
          return {};
        }
      },
      canEdit: Boolean,
      intTypes: {
        type: Array,
        default: () => []
      },
      showCertificates: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        progressActive: false,
        certificatesViewToggle: false,
        apiPath: constants.API_PATH
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsPayrollInterpreter'
      ]),
      showOverlappingParagraphs() {
        return (!this.approvedSkill && !this.declinedSkill) || this.declinedSkill;
      },
      overlappingBlockParagraphs() {
        if (this.declinedSkill) {
          return [{
            classes: ['skill-tile__unapproved-text--red'],
            text: this.$gettext('Sorry, but the attachments you provided were not sufficient to verify your skills.')
          }];
        } else if (!this.approvedSkill) {
          return [{
            text: this.$gettext('Our community manager will review your submission, and check to verify your skills.'),
            classes: ['skill-tile__unapproved-text--orange']
          }, {
            text: this.$gettext('If you are registered on <a href="https://www.tolkeportalen.no/">tolkeportalen.no</a>, then please add your tolkeportalen ID number to your profile for a faster approval process.')
          }, {
            text: this.$gettext('You can accept assignments only after your skill is verified.')
          }];
        }
      },
      qualifsList() { return this.$store.state.selectsData.qualification; },
      isAnyCertifs() { return this.oneSkill.certificates && this.oneSkill.certificates.length; },
      supportText() {
        const template = this.$gettext('We would like to get in touch with you and find a way to fix this, so please email us at <a href="mailto:%{support}">%{support}</a>');
        return this.$gettextInterpolate(template, {support: this.$getString('domainData', 'supportEmail')});
      },
      approvedSkill() { return this.oneSkill.approved; },
      declinedSkill() { return this.oneSkill.declined; },
      langFromName() {
        if (this.$store.state.selectsData.language && this.$store.state.selectsData.language.length) {
          for (const lang of this.$store.state.selectsData.language) {
            if (lang.id == this.oneSkill.langFromId) {
              return lang.name;
            }
          }
        }
      },
      langToName() {
        if (this.$store.state.selectsData.language && this.$store.state.selectsData.language.length) {
          for (const lang of this.$store.state.selectsData.language) {
            if (lang.id == this.oneSkill.langToId) {
              return lang.name;
            }
          }
        }
      },
      qualificationName() {
        let qualifName = '';
        if (this.$store.state.selectsData.qualification && this.$store.state.selectsData.qualification.length) {
          for (const qialif of this.$store.state.selectsData.qualification) {
            if (qialif.id == this.oneSkill.qualificationId) {
              qualifName = qialif.name;
            }
          }
        }
        const qualifArr = qualifName?.split(':');
        if (qualifArr && qualifArr.length > 1) {
          return `<b>${qualifArr[0]}</b>:${qualifArr[1]}`;
        }
        return qualifName;
      },
      skillID() {
        return this.oneSkill.id;
      },
      skillType() { return Object.prototype.hasOwnProperty.call(this.oneSkill, 'dialectTo') ? 'interpretation' : 'translation'; },
      isTranslation() { return this.skillType === 'translation'; },
      skillStatus() {
        const template = this.$gettext('Skill status: <b>%{status}</b>');
        let status = '';

        if (!this.approvedSkill && !this.declinedSkill) status = this.$gettext('Pending');
        if (this.declinedSkill) status = this.$gettext('Declined');
        if (this.approvedSkill) status = this.$gettext('Approved');
        return this.$gettextInterpolate(template, {status});
      },
      skillTitle() {
        if (this.skillType == 'translation') return this.$gettext('Translating');
        else if (this.skillType == 'interpretation') return this.$gettext('Interpreting');
      }
    },
    methods: {
      ...mapMutations('EditProfileStore', ['setSkill']),
      ...mapMutations('TTManageSupplierSkill', ['clearStore', 'setExistingSkill']),
      ...mapActions('TTManageSupplierSkill', ['updateSkill']),
      openSkillModal() {
        this.$emit('editskill', this.skillID);
      },
      fileLinkWithAuth(link) {
        return this.apiPath + link + `?authorization_token=${this.$fetcher.userToken}&random=${Math.random()}`;
      },
      certifDescription(certif) {
        return certif?.desc || certif.certificateFileName || this.$gettext('Certificate file');
      },
      openLink(file) {
        const a = document.createElement('a');
        a.href = this.fileLinkWithAuth(file.fileUrl);
        a.target = '_blank';
        a.click();
      },
      updateCurrentSkill(status, silent) {
        this.progressActive = true;
        this.setExistingSkill(this.oneSkill);
        this.updateSkill({id: this.oneSkill.id, status, silent}).then((data) => {
          this.progressActive = false;
          this.setSkill(data);
          this.clearStore();
        }).catch((error) => {
          this.progressActive = false;
          this.clearStore();
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        });
      }
    }
  };
</script>

<style>
  .skill-tile__unapproved-text a {
    color: #000;
    text-decoration: underline;
  }

  .skill-tile__qualification b {
    color: #000;
    font-weight: normal;
  }
</style>

<style scoped>
.skill-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 290px;
  height: fit-content;
  margin: 10px 20px 10px;
  border: solid 1px #e9eaef;
  border-radius: 8px;
  background-color: #f8f8fa;
  font-size: 12px;
}

.skill-tile--overlapped {
  min-height: 180px;
}

.skill-tile__wrapper {
  padding: 10px;
}

.skill-tile__langs-cont {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 30px;
  margin-top: 2px;
  padding-right: 35px;
  padding-bottom: 5px;
  padding-left: 20px;
}

.skill-tile__langs-cont::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 15px;
  height: 15px;
  background-image: url(~@assets/imgs/undefined_imgs/translation_icon.svg);
  background-position: 50% 50%;
  background-size: 15px auto;
  background-repeat: no-repeat;
}

.skill-tile__edit-btn {
  position: absolute;
  top: 4px;
  right: 8px;
}

.skill-tile__skill-type {
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 14px;
}

.skill-tile__skill-type--interpretation {
  background-color: rgba(43, 37, 92, 0.2);
}

.skill-tile__skill-type--translation {
  background-color: rgba(0, 132, 137, 0.2);
  color: #008489;
}

.skill-tile__skill-type--declined {
  background-color: rgba(229, 35, 34, 0.5);
  color: #000;
}

.skill-tile__lang--to {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.skill-tile__lang--dash {
  width: 12%;
  text-align: center;
}

.skill-tile__dialect-to {
  font-size: 10px;
}

.skill-tile__qualification-cont {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

.skill-tile__qualification {
  position: relative;
  display: block;
  flex-grow: 1;
  width: 100%;
  padding-left: 20px;
  color: #89898a;
}

.skill-tile__qualification::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-image: url(~@assets/imgs/undefined_imgs/new_education_icon.svg);
  background-position: 2px 50%;
  background-size: 11px auto;
  background-repeat: no-repeat;
}

.skill-tile__certifs-btn {
  display: block;
  flex-shrink: 0;
  width: 30px;
  height: 15px;
  border-radius: 50%;
  background-image: url(~@assets/imgs/undefined_imgs/paperclip_icon.svg);
  background-image: var(--image-paperclip-icon);
  background-position: 50% 50%;
  background-size: 15px auto;
  background-repeat: no-repeat;
}

.skill-tile__certifs-btn:focus {
  box-shadow: var(--default-focus-shadow);
  outline: none;
}

.skill-tile__certifs-btn:hover {
  background-image: url(~@assets/imgs/undefined_imgs/paperclip_icon_hover.svg);
  box-shadow: none;
}

.skill-tile__certifs-btn:active {
  background-image: var(--image-paperclip-active-icon);
  box-shadow: none;
}

.skill-tile__certifs-cont {
  display: block;
  flex-grow: 1;
  width: 100%;
  padding-top: 10px;
}

.skill-tile__one-certif-link,
.skill-tile__one-certif-link:focus,
.skill-tile__one-certif-link:active {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
  padding-left: 20px;
  color: #000;
  line-height: 16.8px;
  text-overflow: ellipsis;
}

.skill-tile__one-certif-link::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 2px;
  display: block;
  width: 19px;
  height: 26px;
  background-image: url(~@assets/imgs/undefined_imgs/certificate_icon.svg);
  background-position: 0 50%;
  background-size: 12px auto;
  background-repeat: no-repeat;
}

.skill-tile__one-certif-link:hover {
  text-decoration: underline;
}

.skill-tile__unapproved-wrapper {
  position: absolute;
  top: 38px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 10px 20px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: rgba(248, 248, 250, 0.9);
  font-size: 8px;
}

.skill-tile__unapproved-wrapper--declined {
  background-color: rgba(229, 35, 34, 0.2);
}

.skill-tile__unapproved-text-cont {
  display: block;
  align-self: flex-end;
  width: 100%;
}

.skill-tile__unapproved-text {
  display: block;
  width: 100%;
}

.skill-tile__unapproved-text:not(:last-child) {
  margin-bottom: 10px;
}

.skill-tile__unapproved-text--orange {
  color: #ff9a1e;
}

.skill-tile__unapproved-text--red {
  color: #e52322;
}

.skill-tile__unapproved-text a {
  color: #000;
  text-decoration: underline;
}

.skill-tile__action-wrapper {
  width: 100%;
  margin-top: 15px;
}

.skill-tile__btns-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% + 10px);
  margin: auto -5px 0;
}

.skill-tile__status {
  width: 100%;
}

.skill-tile__btn {
  width: calc(50% - 10px);
  margin: 5px;
}

@media (max-width: 767px) {
  .skill-tile {
    width: 330px;
    margin: 10px auto;
  }

  .skill-tile__langs-cont {
    height: auto;
  }

  .skill-tile__lang--to {
    white-space: normal;
    word-break: break-all;
  }
}
</style>
