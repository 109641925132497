(<template>
  <section class="public-settings__main-wrapper">
    <h2 class="profile__section-title">{{ $gettext("Customize your profile") }}</h2>
    <section class="public-settings__text-wrapper">
      <p class="public-settings__simple-text">
        {{ $gettext("Control how you appear when people search for you on Google, Yahoo!, Bing, etc.") }}
      </p>
      <sk-radio :item-name="'level'"
                :preselected-value="newLevel"
                :item-value="'users'"
                :item-text="text2"
                class="public-settings__radio-label"
                @radiochanged="catchRadio" />
      <sk-radio :item-name="'level'"
                :preselected-value="newLevel"
                :item-value="'internet'"
                :item-text="text3"
                class="public-settings__radio-label"
                @radiochanged="catchRadio" />
      <section v-if="isSettingsActive"
               class="public-settings__checkboxes-wrapper">
        <sk-checkbox :item-text="text4"
                     :preselected-value="createValue('avatar')"
                     :item-value="'avatar'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text5"
                     :preselected-value="createValue('name')"
                     :item-value="'name'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text6"
                     :preselected-value="createValue('sex')"
                     :item-value="'sex'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text7"
                     :preselected-value="createValue('address')"
                     :item-value="'address'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text8"
                     :preselected-value="createValue('skills')"
                     :item-value="'skills'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text10"
                     :preselected-value="createValue('calendar')"
                     :item-value="'calendar'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text11"
                     :preselected-value="createValue('about')"
                     :item-value="'about'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text12"
                     :preselected-value="createValue('expertise_areas')"
                     :item-value="'expertise_areas'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text13"
                     :preselected-value="createValue('interpretation_methods')"
                     :item-value="'interpretation_methods'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text14"
                     :preselected-value="createValue('session_types')"
                     :item-value="'session_types'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text15"
                     :preselected-value="createValue('educations')"
                     :item-value="'educations'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text16"
                     :preselected-value="createValue('courses')"
                     :item-value="'courses'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
        <sk-checkbox :item-text="text17"
                     :preselected-value="createValue('exp_in_hours')"
                     :item-value="'exp_in_hours'"
                     class="public-settings__checkbox-label"
                     @checkboxvaluechanged="catchSetting" />
      </section>
      <div v-if="isSomethingChanged">
        <p v-if="skillNote"
           class="public-settings__skill-note"
           v-html="text18"></p>
        <submit-btns :submit-handler="submitHandler"
                     :cancel-handler="resetValues" />
      </div>
    </section>
  </section>
</template>)

<script>
  import SubmitBtns from '@/components/profile_components/edit/ModalSubmitBtns';

  export default {
    components: {
      'submit-btns': SubmitBtns
    },
    props: {
      level: {
        type: String,
        default: ''
      },
      settings: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        newLevel: this.level,
        newSettings: [...this.settings],
        skillNote: false
      };
    },
    computed: {
      isSomethingChanged() {
        if (this.newLevel != this.level) {
          return true;
        }
        return !this.compareSettings(this.newSettings, this.settings);
      },
      isSettingsActive() {
        return this.newLevel === 'internet';
      },
      text1() { return this.$gettext('Hide my profile from everyone. I will apply to assignments by myself.'); },
      text2() { return this.$gettext('Show my profile to logged in users, so they can invite me to their assignment.'); },
      text3() { return this.$gettext('Show my profile to search engines, so everyone in the world can find me'); },
      text4() { return this.$gettext('Profile picture'); },
      text5() { return this.$gettext('Name'); },
      text6() { return this.$gettext('Gender'); },
      text7() { return this.$gettext('Location (city and country)'); },
      text8() { return this.$gettext('Language skills'); },
      text10() { return this.$gettext('Calendar'); },
      text11() { return this.$gettext('About me'); },
      text12() { return this.$gettext('Areas of expertise'); },
      text13() { return this.$gettext('Interpretation methods'); },
      text14() { return this.$gettext('Interpretation types'); },
      text15() { return this.$gettext('Education'); },
      text16() { return this.$gettext('Additional courses'); },
      text17() { return this.$gettext('Interpretation hours'); },
      text18() { return this.$gettext('*You can not choose to display your <b>Calendar</b> without <b>Language skill</b>. We have enabled required options automatically.'); }
    },
    watch: {
      level() {
        this.newLevel = this.level;
      },
      settings() {
        this.newSettings = [...this.settings];
      },
      newSettings() {
        if (this.settings.indexOf('calendar') == -1 && this.newSettings.indexOf('calendar') != -1) {
          if (this.newSettings.indexOf('skills') == -1) {
            this.skillNote = true;
            this.newSettings.push('skills');
          }
        } else {
          this.skillNote = false;
        }
      }
    },
    methods: {
      catchRadio(value) {
        this.newLevel = value;
      },
      catchSetting(bool, value) {
        if (bool) {
          if (this.newSettings.indexOf(value) == -1) {
            this.newSettings.push(value);
          }
        } else {
          if (this.newSettings.indexOf(value) != -1) {
            this.newSettings.splice(this.newSettings.indexOf(value), 1);
          }
        }
      },
      createValue(text) {
        return this.newSettings.indexOf(text) != -1;
      },
      submitHandler() {
        this.$emit('savesettings', {
          level: this.newLevel,
          array: this.newSettings
        });
      },
      resetValues() {
        this.newLevel = this.level;
        this.newSettings = [...this.settings];
      },
      compareSettings(newSettings, oldSettings) {
        if (newSettings.length !== oldSettings.length) {
          return false;
        } else {
          for (const item of oldSettings) {
            if (newSettings.indexOf(item) === -1) {
              return false;
            }
          }
          return true;
        }
      }
    }
  };
</script>

<style>
  .public-settings__main-wrapper {
    display: block;
    width: 100%;
  }

  .public-settings__text-wrapper {
    position: relative;
    display: block;
    width: 100%;
    padding: 20px 10px;
    border-radius: 3px;
    background-color: #f4f6f8;
  }

  .public-settings__simple-text {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .public-settings__radio-label {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 30px;
    font-size: 14px;
  }

  .public-settings__radio-label--with-tooltip {
    position: relative;
    padding-right: 25px;
  }

  .public-settings__checkboxes-wrapper {
    display: block;
    width: 100%;
    padding-top: 10px;
    padding-left: 30px;
  }

  .public-settings__checkbox-label {
    width: 100%;
    margin-bottom: 10px;
  }

  .public-settings__skill-note {
    display: block;
    width: 100%;
    margin: 0;
    font-size: 10px;
  }
</style>
