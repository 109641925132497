<template>
  <section class="profile__main-content-section profile__delete-section">
    <button class="sk-btn sk-btn--white profile__delete-account"
            @click="deleteAccount">{{ $gettext('Delete my account') }}</button>
  </section>
</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'delete-account-btn',
    computed: {
      ...mapGetters('UserInfoStore', ['maxShortNoticeCancellation']),
    },
    methods: {
      deleteAccount() {
        this.$store.commit('ModalStore/setModal', {
          component: 'delete-account-modal',
          width: 600,
          data: {
            title: this.$gettext('Delete my account'),
            context: this,
            maxShortNoticeCancellation: this.maxShortNoticeCancellation
          }
        });
      }
    },
  };
</script>
