// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/checkboxes_icons/star_rate_dark.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/checkboxes_icons/star_rate_checked_dark.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.prof-avatar-name__avatar-edit-btn {\n  position: absolute;\n  right: -5px;\n  bottom: 0;\n  background-color: white;\n}\n.prof-avatar-name__name-edit-btn {\n  position: absolute;\n  right: 0;\n  bottom: 0;\n}\n.prof-avatar-name__tolkid-edit-btn {\n  position: absolute;\n  top: 0;\n  right: 0;\n}\n.prof-avatar-name__rate .rate__star.rate__star {\n  width: 14px;\n  height: 14px;\n  margin-right: 2px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 14px auto;\n}\n.prof-avatar-name__rate .rate__star.is-filled,\n.rate--editable .rate__star:hover,\n.rate--editable .rate__star:hover ~ .rate__star {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
