(<template>
  <article class="prof-one-experience__wrapper">
    <div class="prof-one-experience__header">
      <button v-if="canEdit"
              class="edit-pencil-btn--outlined prof-one-experience__edit-btn"
              @click="openEditModal"></button>
      <h4 class="prof-one-experience__company">{{ employer }} |&nbsp;</h4>
      <p class="prof-one-experience__duration">
        {{ duration }}
      </p>
    </div>
    <p class="prof-one-experience__description">{{ assignmentInformation }}</p>
  </article>
</template>)

<script>
  export default {
    props: {
      experience: {
        type: Object,
        default() {
          return {};
        }
      },
      canEdit: Boolean
    },
    computed: {
      experienceID() {
        return this.experience.id;
      },
      employer() { return this.experience?.employer; },
      assignmentInformation() { return this.experience?.assignmentInformation; },
      yearFrom() { return this.$moment({year: this.experience?.yearFrom, month: this.experience?.monthFrom - 1, day: 1}).format('MMM YYYY'); },
      yearTo() { return this.$moment({year: this.experience?.yearTo, month: this.experience?.monthTo - 1, day: 1}).format('MMM YYYY'); },
      duration() { return `${this.yearFrom} - ${this.yearTo}`; }
    },
    methods: {
      openEditModal() {
        this.$emit('editExperience', this.experienceID);
      }
    }
  };
</script>

<style scoped>
  .prof-one-experience__wrapper {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .prof-one-experience__wrapper:not(:last-child) {
    border-bottom: 1px solid rgba(227, 227, 229, 0.99);
  }

  .prof-one-experience__header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .prof-one-experience__company {
    font-size: 14px;
  }

  .prof-one-experience__duration {
    position: relative;
    display: inline-block;
    color: #89898a;
    font-size: 14px;
  }

  .prof-one-experience__description {
    margin: 15px 0;
    font-size: 13px;
    opacity: 0.9;
  }

  .prof-one-experience__edit-btn {
    position: absolute;
    right: 0;
    z-index: 1;
  }
</style>
