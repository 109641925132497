<script>
  import {mapState, mapGetters} from 'vuex';
  import helpers from '@/helpers';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsNotInterpreter',
        'userIsInterpreter',
        'userIsSeller',
        'userIsManager'
      ]),
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData || {},
        profilePerson: ({profileData}) => profileData?.person || {},
        tools: (state) => state.tools || '',
        experiences: (state) => state.experiences || '',
        publicFeedbacks: (state) => state.publicFeedbacks || {},
        profileAddress: (state) => {
          return state.profileData && state.profileData.address ? state.profileData.address : {};
        }
      }),
      // profile data
      profileUserRole() { return this.profileData.userRole || ''; },
      stats() { return this.profileData.stats || ''; },
      tolkId() { return this.profileData.tolkId || ''; },
      education() { return this.profileData.educations || ''; },
      feedbacks() { return this.publicFeedbacks.feedbacks || ''; },
      totalFeedbacks() { return this.publicFeedbacks.pages || 1; },
      intTypes() { return this.profileData.sessionTypes || ''; },
      courses() { return this.profileData.courses || ''; },
      intHours() { return this.profileData.expInHours || ''; },
      expertiseAreas() { return this.profileData.expertiseAreas || ''; },
      skills() { return this.profileData ? this.profileData.skills || [] : ''; },
      translatorSkills() { return this.profileData ? this.profileData?.translator?.skills || [] : ''; },
      interpretationMethods() { return this.profileData.interpretationMethods || ''; },
      translationMethods() { return this.profileData?.translator?.translationMethods || ''; },
      referralInfo() { return this.profileData.referrale || ''; },
      referalEnabled() { return this.profileData ? this.profileData.referral_feature || true : ''; },
      percents() { return this.profileData.fillPercentage || ''; },
      // person information
      uid() { return this.profilePerson.uid || ''; },
      gender() { return this.profilePerson.sex || ''; },
      about() { return this.profilePerson.about || ''; },
      avatar() { return this.profilePerson.avatar || ''; },
      firstName() { return this.profilePerson.firstName || ''; },
      lastName() { return this.profilePerson.lastName || ''; },
      dateOfBirth() { return this.profilePerson.dob || ''; },
      userEmail() { return this.profilePerson.email || ''; },
      userId() { return this.profilePerson.id || ''; },
      isBookableUser() { return this.profileData.bookable || false; },
      isBlockedByStatusInterpreter() {
        return this.$isGodmode()
          && this.profileUserRole === 'server'
          && helpers.interpreterInfo.іsBlockedInterpreter(this.profileData);
      },
      // authProviders() { return this.profilePerson.authProviders || []; },
      // address information
      city() { return this.profileAddress.city || ''; },
      country() { return this.profileAddress.country || ''; },
      addressLine1() { return this.profileAddress.line_1 || ''; },
      addressLine2() { return this.profileAddress.line_2 || ''; },
      addressPostcode() { return this.profileAddress.postcode || ''; },
      addressCounty() { return this.profileAddress.county || ''; },
      // person phone
      phoneCode() { return this.profilePerson.phone ? this.profilePerson.phone.code : ''; },
      phoneNumber() { return this.profilePerson.phone ? this.profilePerson.phone.number : ''; },
      alternativePhoneCode() { return this.profilePerson.alternativePhone ? this.profilePerson.alternativePhone.code : ''; },
      alternativePhoneNumber() { return this.profilePerson.alternativePhone ? this.profilePerson.alternativePhone.number : ''; },
      fullPhone() { return this.phoneCode && this.phoneNumber ? `${this.phoneCode}${this.phoneNumber}` : ''; },
      alternativeFullPhone() { return this.alternativePhoneCode && this.alternativePhoneNumber ? `${this.alternativePhoneCode}${this.alternativePhoneNumber}` : ''; },
      jobEntity() { return this.profilePerson.jobEntity; },
      // other props
      badges() { return this.profileData.badges || []; },
      isTranslator() { return this.userIsInterpreter ? this.userIsSeller : true; },
      isAnySkills() {
        if (this.skills && this.skills.length) {
          for (const skill of this.skills) {
            if (skill.approved) {
              return true;
            }
          }
        }
        if (this.translatorSkills && this.translatorSkills.length) {
          for (const skill of this.translatorSkills) {
            if (skill.approved) {
              return true;
            }
          }
        }
        return false;
      },
      isAnyInterpretationSkills() {
        if (this.skills && this.skills.length) {
          for (const skill of this.skills) {
            if (skill.approved) {
              return true;
            }
          }
        }
        return false;
      }
    },
    watch: {
      $route() {
        this.refetchData();
      }
    },
    methods: {
      getEditProfileLink(name) {
        return this.$makeRoute({name, params: {id: this.userUid}});
      },
      refetchData() {
        this.$store.commit('EditProfileStore/removeUserProfile');
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      }
    }
  };
</script>
