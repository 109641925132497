(<template>
  <article class="prof-avatar-name__wrapper">
    <div class="prof-avatar-name__top-row">
      <figure class="prof-avatar-name__avatar-figure">
        <tt-avatar :avatar="avatar"
                   class="prof-avatar-name__avatar" />
        <button v-if="canEdit"
                class="edit-pencil-btn--outlined prof-avatar-name__avatar-edit-btn"
                @click="openEditModal"></button>
        <interpreter-blocked-status v-if="isBlockedByStatus"
                                    :interpreter-data="profileData"
                                    class="prof-avatar-name__blocked-status" />
      </figure>
      <section class="prof-avatar-name__name-side-wrapper">
        <div>
          <div class="prof-avatar-name__name-paragraph">
            <span class="prof-avatar-name__name-text"
                  itemprop="name">{{ firstName }}</span>
            <span class="prof-avatar-name__name-text"
                  itemprop="familyName">{{ lastName }}
              <button v-if="showGodmodeLink"
                      class="sk-link sk-link--default"
                      @click="loginAsUser">{{ godmodeLinkText }}</button>
            </span>
            <p v-if="tolkIdText && canEdit && userIsInterpreter"
               class="prof-avatar-name__tolkid-paragraph">
              <span class="prof-avatar-name__tolkid-text">{{ tolkIdText }}</span>
            </p>
            <button v-if="canEdit"
                    class="edit-pencil-btn--outlined prof-avatar-name__name-edit-btn"
                    @click="openEditModal"></button>
          </div>
          <p v-if="shouldWeShowAddress"
             class="prof-avatar-name__location-paragraph">
            <span itemprop="gender">{{ computedGender }}</span>
            <span itemprop="workLocation">{{ computedAddress }}</span>
          </p>
          <div v-if="stats && ($isGodmode() || !canEdit)"
               class="prof-avatar-name__rate-cont">
            <rating :rate="averageRating"
                    class="prof-avatar-name__rate" />
            <p class="prof-avatar-name__reviews">{{ reviewsText }}</p>
          </div>
        </div>
      </section>
      <div class="prof-avatar-name__action-btns">
        <favor-btns v-if="shouldWeShowFavorBlockBtns"
                    :first-name="firstName"
                    :disable="userIsInterpreter"
                    :uid="uid" />
        <dirbook-btn v-if="showBookingButton"
                     :inter-uid="uid"
                     :disable="userIsInterpreter"
                     :is-blocked="isBlockedInterpreter"
                     class="prof-avatar-name__booking" />
      </div>
    </div>
    <div v-if="showPercents"
         class="percentage-wrapper">
      <div class="persentage-title__text">
        <h4>{{ completedText }}</h4>
        <p class="persentage-title__text-number">{{ `${percents}%` }}</p>
      </div>
      <horizontal-progress class="horizontal-progress"
                           :percents="percents"
                           :subtitle="progressSubtitle" />
    </div>
    <!--<label v-if="shouldWeShowEditBtn"-->
    <!--class="prof-avatar-name__to-edit-page-btn">-->
    <!--<a :href="linkToEditPage"></a>-->
    <!--</label>-->
  </article>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import Rating from '@/components/shared_components/Rating';
  import FavorBtns from '@/components/profile_components/show/FavorBtns';
  import DirbookBtn from '@/components/profile_components/direct_booking/ProfileDirbookBtn';
  import InterpreterBlockedStatus from '@/components/interpreters_components/InterpreterBlockedStatus';

  export default {
    components: {
      rating: Rating,
      'favor-btns': FavorBtns,
      'dirbook-btn': DirbookBtn,
      'interpreter-blocked-status': InterpreterBlockedStatus
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('EditProfileStore', {
        percents: (state) => state.profileData.fillPercentage || ''
      }),
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userIsInterpreter',
        'userIsNotInterpreter',
        'userCanCreateDirectJobs',
        'userIsManager'
      ]),
      paramsList() { return this.params || {}; },
      avatar() { return this.paramsList.avatar; },
      firstName() { return this.paramsList.firstName; },
      lastName() { return this.paramsList.lastName; },
      uid() { return this.paramsList.uid; },
      id() { return this.paramsList.id; },
      tolkId() { return this.paramsList.tolkId; },
      gender() { return this.paramsList.gender; },
      city() { return this.paramsList.city; },
      country() { return this.paramsList.country; },
      stats() { return this.paramsList.stats; },
      canEdit() { return this.paramsList.canEdit; },
      profileData() { return this.paramsList.profileData; },
      isBookableUser() { return this.paramsList.bookable; },
      isBlockedByStatus() { return this.paramsList.blockedByStatus; },
      isBlockedInterpreter() {
        return this.profileData.relation == 'blocked';
      },
      showBookingButton() {
        return this.userUid
          ? this.uid && this.isBookableUser
          : true;
      },
      tolkIdText() {
        return this.tolkId ? `${this.$gettext('Tolkeportalen ID:')} ${this.tolkId}` : '';
      },
      fadeActivator() {
        if (this.clientWidth < 769) {
          return false;
        } else {
          return this.scrollValue >= 15;
        }
      },
      godmodeLinkText() { return this.params.jobEntity === 'supplier' ? this.$gettext('[T]') : this.$gettext('[K]'); },
      showGodmodeLink() { return this.uid && this.$isGodmode(); },
      statsInfo() { return this.stats || {}; },
      averageRating() { return this.statsInfo.averageRating || 0; },
      reviewsCount() { return this.statsInfo.reviewsCount || 0; },
      reviewsText() {
        return this.$gettextInterpolate(this.$gettext('%{ n } reviews'), {n: this.reviewsCount});
      },
      shouldWeShowAddress() {
        return (!this.canEdit && (this.gender || this.city || this.country));
      },
      shouldWeShowFavorBlockBtns() {
        if (this.userIsManager) return false;
        if (this.$route.name === 'BuyerUserProfile' && this.userUid === this.$route.params.id) return false;
        return this.$route.name != 'PublicProfile' && this.$route.name != 'ServerProfileEdit' && this.$route.name != 'BuyerUserProfileEdit';
      },
      computedAddress() {
        if (this.city || this.country) {
          if (this.city && !this.country) {
            return `${this.city}`;
          } else if (!this.city && this.country) {
            return `${this.country}`;
          } else if ((this.city && this.country)) {
            return `${this.city}, ${this.country}`;
          }
        } else {
          return '';
        }
      },
      computedGender() {
        let gender = '';
        if ((this.gender || this.gender === 0) && this.$helperData.getSelectGenders(this) && this.$helperData.getSelectGenders(this).length) {
          for (const g of this.$helperData.getSelectGenders(this)) {
            if (this.gender == g.id) {
              gender = g.name;
            }
          }
        }
        if (this.city || this.country) {
          return `${gender} |`;
        } else {
          return gender;
        }
      },
      showPercents() {
        return this.userIsInterpreter && this.percents && this.params.showPercentage;
      },
      progressSubtitle() { return this.$gettext('Complete your profile so you can get more assignments!'); },
      completedText() { return this.$gettext('Completed profile'); }
    },
    methods: {
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'avatar-name-modal',
          data: this.makeAvatarNameModalData()
        });
      },
      makeAvatarNameModalData() {
        return {
          title: this.$gettext('Edit info'),
          context: this,
          data: {
            avatar: this.avatar,
            firstName: this.firstName,
            lastName: this.lastName,
            tolkId: this.userIsInterpreter ? this.tolkId : ''
          }
        };
      },
      updateAvatarName(obj) {
        this.$store.dispatch('EditProfileStore/updateUserProfile', {
          id: this.$route.params.id,
          form: obj
        }).then(() => {
          this.$emit('updatesuccess');
        }).catch((error) => {
          this.$emit('updatefailed', error);
        });
      },
      loginAsUser() {
        const params = {
          uid: this.uid,
          admin_uid: this.$store.state.UserInfoStore.adminInfo.uid,
          notifs: false
        };

        this.$store.dispatch('TTAuthStore/getGodModeToken', params).then((data) => {
          const link = helpers.getGodmodeLoginLink({
            userId: this.id,
            userUid: this.uid,
            token: data.token
          });
          window.open(link, '_blank');
        }).catch(() => {});
      }
    },
  };
</script>

<style>
  .prof-avatar-name__avatar-edit-btn {
    position: absolute;
    right: -5px;
    bottom: 0;
    background-color: white;
  }

  .prof-avatar-name__name-edit-btn {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .prof-avatar-name__tolkid-edit-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .prof-avatar-name__rate .rate__star.rate__star {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    background-image: url(~@assets/imgs/checkboxes_icons/star_rate_dark.svg);
    background-size: 14px auto;
  }

  .prof-avatar-name__rate .rate__star.is-filled,
  .rate--editable .rate__star:hover,
  .rate--editable .rate__star:hover ~ .rate__star {
    background-image: url(~@assets/imgs/checkboxes_icons/star_rate_checked_dark.svg);
  }
</style>

<style scoped>
.prof-avatar-name__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.prof-avatar-name__top-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.prof-avatar-name__to-edit-page-btn {
  display: none;
}

.prof-avatar-name__avatar-figure {
  position: relative;
  width: 103px;
  height: 103px;
}

.prof-avatar-name__blocked-status {
  position: absolute;
  top: 0;
  right: 0;
}

.prof-avatar-name__avatar {
  width: 100%;
  height: 100%;
}

.prof-avatar-name__name-side-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
}

.prof-avatar-name__name-paragraph {
  position: relative;
  display: inline-block;
  width: auto;
  margin: 0;
  padding-right: 35px;
  font-weight: bold;
  font-size: 25px;
  line-height: 1;
}

.prof-avatar-name__name-text {
  display: block;
  min-width: 110px;
  line-height: 1;
}

.prof-avatar-name__location-paragraph {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  opacity: 0.5;
}

.prof-avatar-name__tolkid-paragraph {
  position: relative;
  display: inline-block;
  min-height: 30px;
  margin: 0;
  margin-top: 5px;
  margin-right: -35px;
  font-size: 10px;
  line-height: 30px;
}

.prof-avatar-name__tolkid-edit-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.prof-avatar-name__rate-cont {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
}

.prof-avatar-name__reviews {
  padding: 0 4px;
  font-size: 12px;
  opacity: 0.5;
}

.prof-avatar-name__booking {
  max-width: 297px;
  margin-top: 20px;
  padding: 0 20px;
  text-transform: uppercase;
}

.percentage-wrapper {
  margin: -42px 0 0;
}

.persentage-title__text {
  display: flex;
  justify-content: space-between;
}

.persentage-title__text-number {
  width: 20px;
  padding-right: 30px;
  font-weight: 600;
}

.horizontal-progress {
  width: 600px;
}

@media (max-width: 1015px) {
  .percentage-wrapper {
    margin: 20px 0;
  }
}

@media (max-width: 767px) {
  .prof-avatar-name__wrapper {
    flex-direction: column;
  }

  .prof-avatar-name__booking {
    width: 100%;
    max-width: 330px;
    padding: 0 20px;
  }

  .prof-avatar-name__to-edit-page-btn {
    display: none;
  }

  .prof-avatar-name__avatar-figure {
    position: relative;
    width: 103px;
    height: 103px;
  }

  .prof-avatar-name__blocked-status {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
  }

  .prof-avatar-name__to-edit-page-btn a {
    display: block;
    width: 30px;
    height: 30px;
    border: 0;
    background-color: transparent;
    background-image: url(~@assets/imgs/undefined_imgs/edit_pencil_icon.svg);
    background-position: 50% 50%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .profile__whole-page-cont--business-view .prof-avatar-name__wrapper {
    flex-grow: 1;
    flex-shrink: 2;
    flex-direction: row;
    align-items: flex-start;
    width: auto;
    min-width: initial;
    margin: 10px 0;
    margin-right: auto;
  }

  .profile__whole-page-cont--business-view .prof-avatar-name__avatar-figure {
    width: 100px;
    height: 100px;
    margin-bottom: 0;
  }

  .profile__whole-page-cont--business-view .prof-avatar-name__name-paragraph {
    padding: 0;
    font-size: 28px;
    text-align: left;
    overflow-wrap: break-word;
  }

  .profile__whole-page-cont--business-view .prof-avatar-name__location-paragraph {
    margin-bottom: 0;
  }

  .profile__whole-page-cont--business-view .prof-avatar-name__name-side-wrapper {
    align-items: center;
    margin-top: 45px;
    margin-left: 15px;
  }

  .prof-avatar-name__name-paragraph {
    display: table-caption;
    width: 100%;
    margin-right: 45px;
  }

  .prof-avatar-name__rate-cont {
    display: block;
  }

  .prof-avatar-name__action-btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }

  .prof-favor-block {
    width: 100%;
    max-width: 330px;
  }

  .horizontal-progress {
    width: 100%;
  }

  .percentage-wrapper {
    margin: 30px 0;
  }

  .prof-avatar-name__name-side-wrapper {
    max-width: 170px;
  }
}
</style>
