// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/assignment_types/job_type_video_icon_profile.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/assignment_types/job_type_phone_icon_profile.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/assignment_types/job_type_location_icon.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@assets/imgs/assignment_types/job_type_hub_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "\n.prof-areas-methods__list[data-v-1e51cd25] {\n  display: block;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  list-style-type: none;\n}\n.prof-areas-methods__list-item[data-v-1e51cd25] {\n  display: block;\n  width: 100%;\n  height: 25px;\n  margin-bottom: 10px;\n  background-repeat: no-repeat;\n  line-height: 25px;\n}\n.list-style--default[data-v-1e51cd25] {\n  position: relative;\n  display: list-item;\n  margin-left: 20px;\n  list-style-type: disc;\n}\n.prof-areas-methods__list-item[data-v-1e51cd25]:last-child {\n  margin-bottom: 0;\n}\n.prof-areas-methods__list--title[data-v-1e51cd25] {\n  font-size: 14px;\n}\n.prof-areas-methods__list-subtitle[data-v-1e51cd25] {\n  color: #89898a;\n  font-size: 13px;\n}\n.prof-areas-methods__list-item--video[data-v-1e51cd25] {\n  padding-left: 40px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 0 30%;\n  background-size: 27px 15px;\n}\n.prof-areas-methods__list-item--phone[data-v-1e51cd25] {\n  padding-left: 40px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: 0 30%;\n  background-size: 26px 25px;\n}\n.prof-areas-methods__list-item--in_person[data-v-1e51cd25] {\n  padding-left: 40px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-position: 0.15% 30%;\n  background-size: 27px 25px;\n}\n.prof-areas-methods__list-item--videoroom[data-v-1e51cd25] {\n  padding-top: 5px;\n  padding-left: 40px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-position: 0.25% 30%;\n  background-size: 30px 25px;\n}\n", ""]);
// Exports
module.exports = exports;
