(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ titleText }}</span>
      <button v-if="canEdit && isAnyMethods"
              class="edit-pencil-btn--outlined profile__title-edit-btn"
              @click="openMethodsModal"></button>
      <button v-else-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openMethodsModal"></button>
    </h2>
    <div v-if="isAnyMethods">
      <list :rendered-list="methods"
            list-style="default" />
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openMethodsModal">{{ addBtnHint }}</button>
    </div>
  </section>
</template>)

<script>
  import List from '@/components/profile_components/show/List';

  export default {
    components: {
      list: List
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      methods() { return this.paramsList.methods; },
      canEdit() { return this.paramsList.canEdit; },
      isTranslation() { return this.paramsList.isTranslation || false; },
      isAnyMethods() {
        if (this.methods) {
          for (let i = 0, lim = this.methods.length; i < lim; i += 1) {
            if (this.methods[i].checked) {
              return true;
            }
          }
        }

        return false;
      },
      titleText() {
        return this.isTranslation
          ? this.$gettext('Translation methods')
          : this.$gettext('Interpretation methods');
      },
      addBtnHint() {
        return this.isTranslation
          ? this.$gettext('Add your preferred translation methods')
          : this.$gettext('Add your preferred interpretation methods');
      },
    },
    methods: {
      makeIntMethodsModalData() {
        return {
          title: this.titleText,
          context: this,
          data: {
            data: JSON.parse(JSON.stringify(this.methods)),
            type: this.isTranslation ? 'translationMethods' : 'interpretationMethods'
          }
        };
      },
      openMethodsModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'checkboxes-modal',
          data: this.makeIntMethodsModalData()
        });
      },
      updateMethods(data) {
        const payload = this.isTranslation
          ? {
            uid: this.$store.getters['UserInfoStore/userUid'],
            translation_methods: data
          }
          : {
            id: this.$route.params.id,
            form: data
          };
        const action = this.isTranslation ? 'EditProfileStore/updateTranslationMethods' : 'EditProfileStore/updateMethods';
        this.$store.dispatch(action, payload).then(() => {
          this.$emit('updatesuccess');
          if (this.isTranslation) this.$store.dispatch('EditProfileStore/getUserProfile', {id: this.$store.getters['UserInfoStore/userUid']});
        });
      }
    }
  };
</script>
