(<template>
  <section class="profile__details-section-wrapper">
    <h2 class="profile__section-title profile__section-title--edit-inside">
      <span>{{ $gettext("Tools/Software") }}</span>
      <button v-if="isAnyTools && canEdit"
              class="edit-pencil-btn--outlined profile__title-edit-btn"
              @click="openEditModal"></button>
      <button v-else-if="canEdit"
              class="add-plus-btn profile__title-edit-btn"
              @click="openEditModal"></button>
    </h2>
    <div v-if="isAnyTools">
      <li v-for="(tool, index) in tools"
          :key="index"
          class="tool-item">{{ tool }}</li>
    </div>
    <div v-else>
      <button class="profile__add-info-btn"
              @click="openEditModal">{{ $gettext("Add your preferred tools and software") }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      paramsList() { return this.params || {}; },
      tools() { return this.paramsList.tools; },
      canEdit() { return this.paramsList.canEdit; },
      isAnyTools() {
        return !!(this.tools && this.tools.length);
      }
    },
    methods: {
      openEditModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'tools-modal',
          data: this.makeToolsModalData()
        });
      },
      makeToolsModalData() {
        return {
          title: this.$gettext('Tools/Software'),
          context: this,
          data: {
            tools: this.tools || []
          }
        };
      },
      updateTools(params) {
        this.$store.dispatch('EditProfileStore/addTools', {
          id: this.$route.params.id,
          params
        }).then(() => {
          this.$emit('updatesuccess');
        });
      }
    }
  };
</script>
