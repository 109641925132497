(<template>
  <section class="profile__details-section-wrapper">
    <div class="profile__details-header-wrapper"
         :class="{'profile__details-header-wrapper--empty-skills': emptySkills}">
      <h2 class="profile__section-title">{{ $gettext("Language skills") }}</h2>
      <div v-if="emptySkills"
           class="prof-skills-section__skills-empty skills-empty">
        <div class="skills-empty__logo"></div>
        <p class="skills-empty__subtitle">{{ $gettext("You haven't added any language skills yet") }}</p>
        <p class="skills-empty__description">{{ $gettext("Please add at least one language skill in order to start receiving job invitations.") }}</p>
      </div>
      <button v-if="canEdit"
              class="sk-btn sk-btn--default prof-skills-section__add-skill-btn"
              @click="openSkillModal">{{ $gettext("Add a new language") }}</button>
    </div>
    <section v-if="isAnyInterSkills || isAnyTransSkills"
             itemprop="hasOfferCatalog"
             itemscope
             itemtype="http://schema.org/OfferCatalog">
      <div v-if="!isMobileView"
           class="prof-skills-section__skills-cont">
        <skill v-for="skill in allSkills"
               :key="skill.id"
               :one-skill="skill"
               :show-certificates="showCertificates"
               :can-edit="canEdit"
               :int-types="intTypes"
               @editskill="openSkillModal" />
      </div>
      <div v-else
           class="prof-skills-section__skills-cont">
        <div class="skills__tabs-buttons">
          <button v-if="isAnyInterSkills"
                  :class="showOtherSkills && isAnyTransSkills ? 'skills-interpreting-tab__button-active' : 'skills-interpreting-tab__button'"
                  @click="showInterSkills">{{ $gettext('Interpreting') }}</button>
          <button v-if="isAnyTransSkills"
                  :class="!showOtherSkills && isAnyInterSkills ? 'skills-interpreting-tab__button-active' : 'skills-interpreting-tab__button'"
                  @click="showTranslationSkills">{{ $gettext('Translating') }}</button>
        </div>
        <div v-if="showOtherSkills">
          <skill v-for="skill in approvedInterSkills"
                 :key="skill.id"
                 :one-skill="skill"
                 :can-edit="canEdit"
                 :show-certificates="showCertificates"
                 :int-types="intTypes"
                 @editskill="openSkillModal" />
        </div>
        <div v-else>
          <skill v-for="skill in approvedTransSkills"
                 :key="skill.id"
                 :one-skill="skill"
                 :can-edit="canEdit"
                 :show-certificates="showCertificates"
                 :int-types="intTypes"
                 @editskill="openSkillModal" />
        </div>
      </div>
    </section>
    <button v-if="canEdit"
            class="sk-btn sk-btn--default prof-skills-section__add-skill-btn prof-skills-section__add-skill-btn--mobile"
            @click="openSkillModal">{{ $gettext("Add a new language") }}</button>
  </section>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import Skill from '@/components/profile_components/show/Skill';

  export default {
    components: {
      skill: Skill
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        showOtherSkills: true
      };
    },
    computed: {
      ...mapState('EditProfileStore', {
        profileData: (state) => state.profileData || {}
      }),
      ...mapGetters('UserInfoStore', [
        'userIsSeller',
        'userIsNotInterpreter'
      ]),
      paramsList() { return this.params || {}; },
      canEdit() { return this.paramsList.canEdit; },
      intTypes() { return this.profileData.sessionTypes || []; },
      allInterpretationSkills() {
        let skills = this.profileData?.skills || [];
        skills = skills.map((skill) => ({
          ...skill,
          industry: 'interpretation'
        }));
        return this.profileData ? skills : '';
      },
      isMobileView() { return this.$store.state.GlobalStore.clientWidth < 768; },
      allTranslationSkillsList() {
        let skills = this.profileData?.translator?.skills || [];
        skills = skills.map((skill) => ({
          ...skill,
          industry: 'translation'
        }));
        return this.profileData ? skills : '';
      },
      isAnyInterSkills() { return !!(this.allInterpretationSkills && this.allInterpretationSkills.length); },
      isAnyTransSkills() { return !!(this.allTranslationSkillsList && this.allTranslationSkillsList.length); },
      emptySkills() { return !this.isAnyInterSkills && !this.isAnyTransSkills; },
      approvedInterSkills() {
        return this.isAnyInterSkills
          ? this.allInterpretationSkills.filter((skill) => skill.approved || this.canEdit)
          : [];
      },
      approvedTransSkills() {
        return this.isAnyTransSkills
          ? this.allTranslationSkillsList.filter((skill) => skill.approved || this.canEdit)
          : [];
      },
      allSkills() {
        if (this.userIsSeller || this.userIsNotInterpreter) {
          const all = [
            ...this.approvedTransSkills,
            ...this.approvedInterSkills
          ];
          return all.sort((x, y) => {
            return new Date(y.createdAt) - new Date(x.createdAt);
          });
        } else {
          return this.approvedInterSkills;
        }
      },
      showCertificates() { return this.$route.name === 'ServerProfileEdit'; }
    },
    methods: {
      showInterSkills() {
        this.showOtherSkills = true;
      },
      showTranslationSkills() {
        this.showOtherSkills = false;
      },
      openSkillModal(id) {
        if (id && typeof id == 'number') {
          const selectedSkill = this.allSkills.filter((item) => item.id == id)[0];
          this.$store.commit('TTManageSupplierSkill/setExistingSkill', selectedSkill);
          if (selectedSkill.certificates && selectedSkill.certificates.length) {
            this.$store.state.PostingStore.AttachmentsStore.attachmentsSelectState = true;
            this.$store.state.PostingStore.AttachmentsStore.attachments = JSON.parse(JSON.stringify(selectedSkill.certificates));
            if (selectedSkill?.certificates && selectedSkill?.certificates.length) this.$store.commit('TTManageSupplierSkill/setNewCertifTitle', selectedSkill.certificates[0].desc);
          }
        }
        this.$store.commit('ModalStore/setModal', {
          component: 'add-skill',
          width: 510,
          data: {
            title: this.$gettext('Language skills'),
            context: this
          }
        });
      }
    },
    mounted() {
      if (!this.isAnyInterSkills) this.showOtherSkills = false;
    }
  };
</script>

<style scoped>
  .prof-skills-section__skills-cont {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -20px;
  }

  .profile__details-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .profile__details-header-wrapper--empty-skills {
    flex-direction: column;
  }

  .skills-interpreting-tab__button {
    margin-right: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
  }

  .skills-interpreting-tab__button-active {
    margin-right: 20px;
    padding-bottom: 10px;
    border-bottom: 3px solid #ff5b24;
    color: #ff5b24;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
  }

  .skills__tabs-buttons {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #e9eaef;
  }

  .prof-skills-section__skills-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .skills-empty p {
    color: #000;
    font-size: 0.86rem;
    line-height: 1.14rem;
    letter-spacing: -0.12px;
  }

  .skills-empty__subtitle {
    margin-top: 16px;
    font-weight: bold;
  }

  .skills-empty__description {
    max-width: 280px;
    margin-bottom: 17px;
    text-align: center;
  }

  .skills-empty__logo {
    width: 115px;
    height: 70px;
    background-image: url(~@assets/imgs/salita/interpreters_icons/profile_skill_background_image.svg);
    background-repeat: no-repeat;
  }

  .prof-skills-section__add-skill-btn {
    max-width: 180px;
  }

  .prof-skills-section__add-skill-btn--mobile {
    display: none;
  }

  @media (max-width: 767px) {
    .prof-skills-section__skills-cont {
      margin: auto;
    }

    .prof-skills-section__add-skill-btn {
      display: none;
    }

    .prof-skills-section__add-skill-btn--mobile {
      display: block;
    }

    .profile__details-header-wrapper {
      margin-bottom: 10px;
    }
  }
</style>
